import React from "react";
import MandantRapView from "../../../../components/filecontent/MandantRapView";

type PropsType = {
  params: any;
};

// markup
const Page = ({ params }: PropsType) => {
  return (
    <div>
      <MandantRapView backendReference={params.fileId} editable={false} belegNrFilter={params.belegNr} />
    </div>
  );
};

export default Page;
